<template>
    <div id="startGroup">
        <div class="container">
            <figure class="image is-90 is-centered">
                <router-link to="/">
                    <img src="/images/textel_logo_90.png" />
                </router-link>
            </figure>

            <div class="has-text-centered mb-4">
                <h2 class="subtitle mt-4">Send an SMS Message</h2>
            </div>

            <div class="notification-parent">
                <div v-if="validationMessage" class="notification is-danger is-light">
                    {{ validationMessage }}
                </div>
                <div v-else-if="sent" class="notification is-success is-light">
                    <button class="delete" @click="sent=false"></button>
                    Your message was sent successfully!
                </div>
                <div v-else-if="sendError" class="notification is-danger is-light">
                    <button class="delete" @click="sendError=''"></button>
                    Error sending message: {{ sendError }}
                </div>
            </div>

            <div class="sms-box-parent">
                <div class="box">
                    <fieldset :disabled="validating || sending || validationMessage">

                        <div class="field">
                            <label class="label">From</label>
                            <div class="control is-expanded" :class="{ 'is-loading': validating }">
                                <div class="dropdown is-fullwidth" :class="{ 'is-active': linesMenuActive}">
                                    <div class="dropdown-trigger is-fullwidth">
                                        <button class="button menu-button is-fullwidth" aria-haspopup="true" aria-controls="lines-menu" @click="linesMenuActive = !linesMenuActive"
                                                @blur="linesMenuActive = false">
                                            <span class="icon">
                                                <font-awesome-icon icon="phone" size="sm" />
                                            </span>
                                            <span>
                                                {{ formatPhoneNumber(selectedLine.tollFree) }} - {{ selectedLine.name }}
                                            </span>
                                            <span v-if="!validating && !sending" class="icon is-right">
                                                <font-awesome-icon icon="angle-down" size="sm" />
                                            </span>
                                        </button>
                                    </div>
                                    <div class="dropdown-menu is-fullwidth" id="lines-menu" role="menu">
                                        <div class="dropdown-content">
                                            <a v-for="(line, index) in lines" class="dropdown-item" @mousedown="selectLine(index)" :key="'line' + index">
                                                {{ formatPhoneNumber(line.tollFree) }} - {{ line.name }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!toPhoneHide && !validating" class="field">
                            <div class="field is-grouped">
                                <p class="control is-expanded">
                                    <label class="label">To Phone Number(s)</label>
                                </p>
                                <p class="control">
                                    <button class="button is-small is-success is-light" @click="addRecipient" :disabled="!toPhoneEditable">
                                        <span class="icon is-small">
                                            <font-awesome-icon icon="plus" size="sm" />
                                        </span>
                                        <span>Add</span>
                                    </button>
                                </p>
                            </div>

                            <div v-for="(to, index) in toNumbers" class="mb-2" :key="'recipient' + index">
                                <div class="field has-addons">
                                    <p class="control">
                                        <a class="button is-static" :disabled="!toPhoneEditable">
                                            +1
                                        </a>
                                    </p>
                                    <p class="control is-expanded has-icons-left has-icons-right">
                                        <input class="input" type="text" maxlength="16" placeholder="Enter to phone number..." v-model="to.value"
                                               @input="formatToNumber(index)" @keyup.enter="send" :disabled="!toPhoneEditable">
                                        <span class="icon is-small is-left">
                                            <font-awesome-icon icon="mobile-alt" />
                                        </span>
                                        <span v-if="index > 0" class="icon is-small is-right" title="Remove Recipient">
                                            <a class="delete is-small" @click="removeRecipient(index)"></a>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">Message Body</label>
                            <div class="control">
                                <textarea class="textarea" maxlength="250" placeholder="Enter message body..." v-model="messageBody"></textarea>
                            </div>
                            <p class="help">{{ messageBody.length }} / 250</p>
                        </div>

                        <div class="field">
                            <div class="control">
                                <button class="button is-link is-fullwidth" :class="{ 'is-loading': sending }" @click="send" :disabled="!messageBody">Send SMS</button>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AgentOutbound',
        data() {
            return {
                validating: true,
                validationMessage: '',
                linesMenuActive: false,
                lines: [],
                selectedLine: {},
                accountId: '',
                agentId: '',
                agentFirstName: '',
                agentLastName: '',
                skillId: '',
                skillNameVar: '',
                toPhoneEditable: true,
                toPhoneHide: false,
                from: '',
                toNumbers: [{ value: '' }],
                messageBody: '',
                sending: false,
                sent: false,
                sendError: ''
            };
        },
        created() {
            var queryString = Object.keys(this.$route.query).map(key => key + '=' + this.$route.query[key]).join('&');

            fetch("/api/conversations/v1/agent-outbound?" + queryString)
                .then(response => response.json())
                .then(data => {
                    if (data && data.lines) {
                        this.lines = data.lines;
                        this.accountId = data.accountId;
                        this.agentId = data.agentId;
                        this.agentFirstName = data.agentFirstName;
                        this.agentLastName = data.agentLastName;
                        this.skillId = data.skillId;
                        this.skillNameVar = data.skillNameVar;
                        this.toPhoneEditable = data.toPhoneEditable;
                        this.toPhoneHide = data.toPhoneHide;

                        if (data.toPhoneNumber) {
                            this.toNumbers = [{ value: data.toPhoneNumber.toString() }];
                            this.formatToNumber(0);
                        }

                        this.selectLine(0);

                        this.validationMessage = null;
                    }
                    else {
                        this.validationMessage = "Validation Error: " + data;
                    }

                    this.validating = false;
                }).catch(() => {
                    this.validationMessage = "Error validating account or line information.";
                    this.validating = false;
                });
        },
        methods: {
            selectLine(index) {
                if (index > -1 && index < this.lines.length) {
                    this.selectedLine = this.lines[index];
                }
            },
            addRecipient() {
                this.toNumbers.push({ value: '' });
            },
            removeRecipient(index) {
                this.toNumbers.splice(index, 1);
            },
            formatToNumber(index) {
                if (index > -1 && index < this.toNumbers.length) {
                    this.toNumbers[index].value = this.formatPhoneNumber(this.toNumbers[index].value);
                }
            },
            formatPhoneNumber(value) {
                if (value) {
                    var x = value.toString().replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                    return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
                }
            },
            send() {
                this.sendError = '';
                this.sent = false;

                if (this.messageBody) {
                    this.sending = true;

                    var destinations = [];
                    for (var key in this.toNumbers) {
                        destinations.push(this.toNumbers[key].value);
                    }

                    var postBody = {
                        lineId: this.selectedLine.lineGuid,
                        accountId: this.accountId,
                        agentId: this.agentId.toString(),
                        agentFirstName: this.agentFirstName,
                        agentLastName: this.agentLastName,
                        skillNameVar: this.skillNameVar,
                        skillId: this.skillId,
                        messageBody: this.messageBody,
                        toPhoneNumber: destinations.join(',')
                    };

                    fetch('/api/conversations/v1/agent-outbound-send', {
                        method: 'post', headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(postBody)
                    })
                        .then(response => {
                            var data = '';

                            if (response.status >= 400) {
                                data = response.text();
                            }
                            else {
                                data = response.json();
                            }

                            return data;
                        })
                        .then(data => {
                            if (data.sentSuccessfully) {
                                this.sent = true;
                                this.toNumbers = [{ value: '' }];
                                this.messageBody = '';
                            }
                            else {
                                this.sendError = data;
                            }

                            this.sending = false;
                        }).catch(e => {
                            this.sendError = e;
                            this.sending = false;
                        });
                }
            }
        }
    };
</script>

<style scoped>
    #startGroup {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .image.is-centered {
        position: relative;
        margin: 0 auto;
    }

    .image.is-90 {
        width: 90px;
    }

    .notification-parent {
        max-width: 32rem;
        margin: 0 auto;
        padding: 0 1.5rem;
        margin-bottom: 1rem;
    }

    .sms-box-parent {
        max-width: 32rem;
        margin: 0 auto;
        padding: 0 1.5rem;
    }

    .is-fullwidth {
        width: 100%;
    }

    .menu-button {
        text-align: left;
        justify-content: left;
    }

        .menu-button .icon:last-child:not(:first-child) {
            margin-left: auto;
        }
</style>