<template>
    <div id="home" class="container">
        <Login />
    </div>
</template>

<script>
    import Login from "./Login.vue";

    export default {
        name: 'Home',
        beforeRouteEnter(to, from, next) {
            if (to || from) {
                next();
            }
        },
        components: {
            Login
        }
    }
</script>

<style scoped>
    #home {
    }
</style>
