<template>
    <div id="login">
        <div class="container">
            <figure class="image is-90 is-centered">
                <router-link to="/">
                    <img src="/images/textel_logo_90.png" />
                </router-link>
            </figure>

            <h2 class="subtitle mt-4">Sign in to Textel Contact Center Portal</h2>

            <div class="login-box-parent">
                <div class="box">
                    <p v-if="!loginValid" class="help is-danger has-text-centered">Invalid username or password</p>

                    <div class="field">
                        <label class="label">Username</label>
                        <div class="control has-icons-left">
                            <input class="input" type="text" placeholder="Enter email or username" v-model="username" @keyup.enter="signIn">
                            <span class="icon is-small is-left">
                                <font-awesome-icon icon="user" />
                            </span>
                        </div>
                    </div>

                    <div class="field">
                        <label class="label is-pulled-left">Password</label>
                        <small class="is-pulled-right">
                            <a href="#" tabindex="-1">Forgot password?</a>
                        </small>

                        <div class="control has-icons-left">
                            <input class="input" type="password" placeholder="Enter password" v-model="password" @keyup.enter="signIn">
                            <span class="icon is-small is-left">
                                <font-awesome-icon icon="lock" />
                            </span>
                        </div>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button class="button is-link is-fullwidth" @click="signIn">Sign in</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Login',
        data() {
            return {
                username: "",
                password: "",
                loginValid: true
            }
        },
        methods: {
            signIn() {
                this.loginValid = false;
            }
        }
    }
</script>

<style scoped>
    #login {
        text-align: center;
        padding-top: 3rem;
    }

    .image.is-centered {
        position: relative;
        margin: 0 auto;
    }

    .image.is-90 {
        width: 90px;
    }

    .login-box-parent {
        max-width: 26rem;
        margin: 0 auto;
        padding: 0 1.5rem;
        text-align: left;
    }
</style>
