<template>
    <div id="account-summary" class="container">
        <div v-if="loading" class="has-text-centered">
            <section class="hero is-fullheight">
                <div class="hero-body">
                    <div class="container">
                        <span class="has-text-info">
                            <font-awesome-icon icon="spinner" size="2x" pulse />
                        </span>
                    </div>
                </div>
            </section>
        </div>

        <div v-if="loadingComplete">
            <img src="images/textel_logo_90.png" width="128" alt="Textel Logo" />

            <h1 class="title mt-1">
                Summary Report for {{ accountSummary.name }}
            </h1>

            <h3 class="subtitle">
                From: {{ accountSummary.dateStart }} To: {{ accountSummary.dateEnd }}
            </h3>

            <div class="columns is-multiline">
                <div class="column is-one-third">
                    <div class="card has-text-centered">
                        <div class="card-content">
                            <p class="title">
                                {{ accountSummary.messageCount }}
                            </p>
                        </div>
                        <footer class="card-footer">
                            <p class="card-footer-item">
                                <span>
                                    Total Message Count
                                </span>
                            </p>
                        </footer>
                    </div>
                </div>

                <div class="column is-one-third">
                    <div class="card has-text-centered">
                        <div class="card-content">
                            <p class="title">
                                {{ accountSummary.conversationCount }}
                            </p>
                        </div>
                        <footer class="card-footer">
                            <p class="card-footer-item">
                                <span>
                                    Conversation Count
                                </span>
                            </p>
                        </footer>
                    </div>
                </div>

                <div class="column is-one-third">
                    <div class="card has-text-centered">
                        <div class="card-content">
                            <p class="title">
                                {{ accountSummary.averageMessagesPerConversation }}
                            </p>
                        </div>
                        <footer class="card-footer">
                            <p class="card-footer-item">
                                <span>
                                    Average Messages Per Conversation
                                </span>
                            </p>
                        </footer>
                    </div>
                </div>

                <div class="column is-one-third">
                    <div class="card has-text-centered">
                        <div class="card-content">
                            <p class="title has-text-success">
                                {{ accountSummary.mobileOriginated }}
                            </p>
                        </div>
                        <footer class="card-footer">
                            <p class="card-footer-item">
                                <span>
                                    Mobile Originated
                                </span>
                            </p>
                        </footer>
                    </div>
                </div>

                <div class="column is-one-third">
                    <div class="card has-text-centered">
                        <div class="card-content">
                            <p class="title has-text-success">
                                {{ accountSummary.agentOriginated }}
                            </p>
                        </div>
                        <footer class="card-footer">
                            <p class="card-footer-item">
                                <span>
                                    Agent Originated
                                </span>
                            </p>
                        </footer>
                    </div>
                </div>
            </div>

            <div class="card">
                <header class="card-header">
                    <p class="card-header-title">
                        Messages Per Day
                    </p>
                </header>
                <div class="card-content">
                    <div class="content">
                        <div id="chartContainer"></div>
                    </div>
                </div>
            </div>

            <div class="card mt-5">
                <header class="card-header">
                    <p class="card-header-title">
                        Line Summary
                    </p>
                </header>
                <div class="card-content">
                    <div class="content">
                        <div class="table-container">
                            <table class="table is-striped">
                                <thead>
                                    <tr>
                                        <th>Phone Number</th>
                                        <th>Message Count</th>
                                        <th>Mobile Originated</th>
                                        <th>Agent Originated</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="line in accountSummary.lineSummary" :key="line.phoneNumber">
                                        <td>
                                            {{ line.phoneNumber }}
                                        </td>
                                        <td>
                                            {{ line.messageCount }}
                                        </td>
                                        <td>
                                            {{ line.mobileOriginated }}
                                        </td>
                                        <td>
                                            {{ line.agentOriginated }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    var CanvasJS = require('../canvasjs.min.js');
    CanvasJS = CanvasJS.Chart ? CanvasJS : window.CanvasJS; 

    export default {
        name: 'AccountSummary',
        data() {
            return {
                accountSummary: null,
                loading: false,
                loadingComplete: false,
                chartRendered: false,
                chart: null
            }
        },
        created() {
            this.fetchData();
        },
        updated() {
            if (this.loadingComplete && !this.chartRendered) {
                this.createChart();
                this.chart.render();
                this.chartRendered = true;
            }
        },
        methods: {
            fetchData() {
                this.loading = true;

                var queryString = Object.keys(this.$route.query).map(key => key + '=' + this.$route.query[key]).join('&');

                fetch("api/reports/v1/account-summary?" + queryString)
                    .then(response => response.json())
                    .then(data => {
                        this.accountSummary = data;
                        this.loading = false;
                        this.loadingComplete = true;
                    }).catch(e => {
                        console.error(e);
                    });
            },
            createChart() {
                var dataPoints1 = [];
                var dataPoints2 = [];
                var dataPoints3 = [];

                for (let i = 0; i < this.accountSummary.dataPoints.length; i++) {
                    dataPoints1.push({
                        x: new Date(this.accountSummary.dataPoints[i].msgDate),
                        y: this.accountSummary.dataPoints[i].msgCount
                    });
                }

                for (let i = 0; i < this.accountSummary.dataPoints.length; i++) {
                    dataPoints2.push({
                        x: new Date(this.accountSummary.dataPoints[i].msgDate),
                        y: this.accountSummary.dataPoints[i].agentOriginated
                    });
                }

                for (let i = 0; i < this.accountSummary.dataPoints.length; i++) {
                    dataPoints3.push({
                        x: new Date(this.accountSummary.dataPoints[i].msgDate),
                        y: this.accountSummary.dataPoints[i].mobileOriginated
                    });
                }

                this.chart = new CanvasJS.Chart("chartContainer", {
                    animationEnabled: true,
                    title: {
                        text: ""
                    },
                    axisX: {
                        valueFormatString: "DD MMM,YY"
                    },
                    axisY: {
                        title: "Message Count",
                        includeZero: true,
                        suffix: ""
                    },
                    data: [
                        {
                            name: "Total",
                            type: "column",
                            dataPoints: dataPoints1,
                            showInLegend: true,
                        }
                        ,
                        {
                            name: "Agent Originated",
                            type: "line",
                            dataPoints: dataPoints2,
                            showInLegend: true,
                        }
                        ,
                        {
                            name: "Mobile Originated",
                            type: "line",
                            dataPoints: dataPoints3,
                            showInLegend: true,
                        }
                    ]
                });
            }
        }
    }
</script>

<style scoped>
    #account-summary {
        padding: 2rem;
    }

    #chartContainer {
        height: 300px;
        width: 100%;
    }
</style>