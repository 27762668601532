<template>
    

<div class="modal" :class="{'modal': true, 'is-active': is_active}">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="box">
      <article class="media">

        <div class="media-content">
          <div class="content">
              <h1>Select a different number:</h1>
            <p v-for="line in numbers" :key="line.PhoneNumber">
                
                <a class="button is-info is-light" 
                    @click="gotoNewPage(line.lineGuid)" >
                    {{line.phoneNumber | phone_format}}
                </a>

            </p>
          </div>
            <button class="button" @click="$emit('hideModal')">Close</button>
        </div>
      </article>
    </div>
  </div>
  <button class="modal-close is-large" aria-label="close"></button>
</div>
</template>

<script>
import phone_format from '../utils/phoneNumbers'
export default {
    name: "phone-number-picker",
    props: ['numbers', 'is_active'],
    filters: {
        phone_format(phoneNumberString) {
            return phone_format.to_human(phoneNumberString)
        }
    },
    data(){
        return{

        }
    },
    methods: {
        gotoNewPage(newid){
            const url = this.$route.query
            var newurl = this.$route.path + "?"

            for (let key in url) {

                if (key.toLowerCase() == "lineid"){
                    newurl += `&${key}=${newid}`
                } else {
                    newurl += `&${key}=${url[key]}`
                }
            
            }

            window.location.href = newurl
        }
        

    },

}
</script>