<template>
    <div id="parent">
        <div id="chat" class="card">
            <header id="chat-header" class="card-header">
                <div class="card-header-title">
                    <span class="image is-64">
                        <img src="/images/textel_logo_90.png" alt="Textel Logo" />
                    </span>

                    <span class="ml-4">
                        {{conversationTitle}}
                    </span>

                </div>

                <div class="card-header-icon" id="header-icon">
                    <div class="dropdown is-right" :class="{ 'is-active': historyMenuActive }">
                        <div class="dropdown-trigger">
                            <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="historyMenuActive = true" @blur="hideHistoryMenu">
                                <span class="icon">
                                    <font-awesome-icon icon="angle-down" size="sm" />
                                </span>
                            </button>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu" >
                            <div class="dropdown-content">
                                <a :href="'./chat-history?conversationId=' + conversationId + '&shaconv=' + conversationId"
                                   target="_blank" class="dropdown-item" @mousedown="navigateToChatHistory(conversationId)">
                                    <span class="icon">
                                        <font-awesome-icon icon="history" size="sm" />
                                    </span>
                                    View User Conversation History
                                </a>
                                <a :href="'./api/conversations/v1/export-history?conversationId=' + conversationId + '&shaconv=' + conversationId" 
                                    @mousedown="navigateToCSV(conversationId)" class="dropdown-item">
                                    <span class="icon">
                                        <font-awesome-icon icon="file-export" size="sm" />
                                    </span>
                                    Export To CSV
                                </a>
                                <a v-if="appContext=='purecloud'" href="#" @mousedown="communicateGetNewNumber" 
                                    class="dropdown-item">
                                    <span class="icon">
                                        <font-awesome-icon icon="arrow-left" size="sm" />
                                    </span>
                                    Text another number
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div id="chat-body" class="card-content">
                <div :class="validating || loading || !isValid || messages === null || messages.length === 0 ? 'content center has-text-centered' : 'content'">
                    <div v-if="validating" class="has-text-centered">
                        <span class="has-text-info">
                            <font-awesome-icon icon="spinner" size="2x" pulse />
                        </span>
                        <p>Validating conversation...</p>
                    </div>

                    <div v-else-if="loading" class="has-text-centered">
                        <span class="has-text-info">
                            <font-awesome-icon icon="spinner" size="2x" pulse />
                        </span>
                        <p>Loading messages...</p>
                    </div>

                    <div v-else-if="!isValid">
                        <p>
                            {{ validationMessage }}
                        </p>
                    </div>

                    <div v-else-if="!loading && (messages === null || messages.length === 0)">
                        <p>
                            No Messages
                        </p>
                    </div>

                    <div v-else>
                       <ChatMessages :messages="messages" :agentName="agentName" :userName="userName" v-on:scroll="scrollChatToBottom" />
                    </div>
                </div>
            </div>

            <footer id="chat-footer" class="card-footer">
                <div class="card-footer-item">
                    <div class="field has-addons is-full-width">
                        <p v-if="validating || !isValid" class="control is-expanded is-full-width">
                            <input class="input" type="text" disabled />
                        </p>
                        <p v-else-if="isSidebar" class="control is-expanded is-full-width has-icons-right">
                            <input class="input" @keyup.enter="sendMessage" v-model="messageText" type="text" placeholder="Enter chat message..." />
                            <span :class="messageText !== '' ? 'icon is-small is-right' : 'icon is-small is-right is-hidden'">
                                <a class="delete is-small" @click="messageText = ''"></a>
                            </span>
                        </p>
                        <p v-else class="control is-expanded is-full-width has-icons-left has-icons-right">
                            <span class="icon">
                                <font-awesome-icon icon="arrow-left" />
                            </span>
                            <input class="input" @keyup.enter="sendMessage" v-model="messageText" 
                                type="text" :placeholder="chatPlaceholder" 
                                :disabled="appContext != 'purecloud' && appContext !='fivenine'" />
                            <span :class="messageText !== '' ? 'icon is-small is-right' : 'icon is-small is-right is-hidden'">
                                <a class="delete is-small" @click="messageText = ''"></a>
                            </span>
                        </p>

                        <p class="control">
                            <a :class="emojisVisible ? 'button is-primary' : 'button'" @click="toggleEmojisVisible"
                               :disabled="validating || loading || !isValid">
                                <span class="icon">
                                    <font-awesome-icon :icon="['far', 'smile']" size="sm" />
                                </span>
                            </a>
                        </p>

                        <p class="control">
                            <a :class="filePickerVisible ? 'button is-primary' : 'button'" @click="toggleFilePickerVisible"
                               :disabled="validating || loading || !isValid">
                                <span class="icon">
                                    <font-awesome-icon icon="paperclip" size="sm" />
                                </span>
                            </a>
                        </p>

                        <p class="control is-narrow">
                            <a class="button is-link" @click="sendMessage" :disabled="validating || loading || !isValid">
                                <span class="icon">
                                    <font-awesome-icon icon="paper-plane" size="sm" />
                                </span>
                                <span>Send</span>
                            </a>
                        </p>
                    </div>
                </div>
            </footer>

            <footer id="chat-footer" :class="emojisVisible ? 'card-footer' : 'card-footer is-hidden'">
                <VEmojiPicker id="emoji-picker" @select="selectEmoji" />
            </footer>

            <footer id="chat-footer" :class="filePickerVisible ? 'card-footer' : 'card-footer is-hidden'">
                <FilePicker :files="attachments" v-on:file-removed="(index) => fileRemoved(index)" v-on:file-added="(files) => fileAdded(files)" />
            </footer>
        </div>
    </div>
</template>

<script>
    import { HubConnectionBuilder } from '@microsoft/signalr';
    import ChatMessages from './ChatMessages';
    import {VEmojiPicker} from 'v-emoji-picker';
    import FilePicker from './FilePicker';

    export default {
        name: 'Chat',
        components: {
            ChatMessages,
            VEmojiPicker,
            FilePicker
        },
        data() {
            return {
                validating: true,
                isValid: false,
                validationMessage: "",
                loading: false,
                conversationId: "",
                messageHub: null,
                messages: [],
                agentName: "A",
                userName: "C",
                messageText: "",
                emojisVisible: false,
                filePickerVisible: false,
                attachments: [],
                historyMenuActive: false,
                dropletSound: null,
                isSidebar: false,
                chatPlaceholder: 'Use Chat To Left',
                conversationTitle: 'Agent Text Conversation',
                appContext: ''
            }
        },
        mounted() {
            this.verifyConversation();

            // appContext only exists if this is a genesys communicate chat
            this.appContext = this.$route.query['appcontext'] || ''
            if (this.appContext == 'purecloud' || this.appContext == 'fivenine') {
                this.chatPlaceholder = 'Enter your message here...'
                this.conversationTitle = 'Conversation history'
            }
            this.dropletSound = new Audio('./audio/water_droplet.mp3');
        },
        beforeDestroyed() {
            this.messageHub.stop();
        },
        methods: {
            verifyConversation() {
                var queryString = Object.keys(this.$route.query).map(key => key + '=' + this.$route.query[key]).join('&');

                if (window.location.pathname === '/conversation/sidebar') {
                    queryString += '&isSidebar=true';
                }

                fetch("/api/conversations/v1/validate?" + queryString)
                    .then(response => response.json())
                    .then(data => {
                        this.isValid = data.isValid;

                        if (this.isValid) {
                            this.isSidebar = data.isSidebar;
                            this.conversationId = this.$route.query['conversationid'] || this.$route.query['conversationId'];
                            this.startMessageHub();
                        }
                        else {
                            this.validationMessage = data.message;
                        }
                        this.validating = false;
                    }).catch(e => {
                        console.error(e);
                        this.validationMessage = "Error Validating Conversation";
                        this.validating = false;
                    });
            }, 
            startMessageHub() {
                this.loading = true;

                this.messageHub = new HubConnectionBuilder()
                    .withUrl("/message-hub")
                    .configureLogging("trace")
                    .withAutomaticReconnect( [0, 1000, 5000, null] )
                    .build();

                this.messageHub.on("addedToGroup", (group) => this.addedToGroup(group));
                this.messageHub.on("showExisting", (messages) => this.showExistingMessages(messages));
                this.messageHub.on("broadcastMessage", (messages) => this.broadcastMessage(messages));
                this.messageHub.on("setAgentName", (name) => this.setAgentName(name));
                this.messageHub.on("setMessageStatus", (messageId, status) => this.setMessageStatus(messageId, status));

                this.messageHub.onclose(function(){
                    console.log("closing")
                })

                this.messageHub.onreconnecting(() => {
                    this.loading = true;
                    console.log("reconnecting")
                    //this.messageHubAfterStart();
                })

                this.messageHub.onreconnected(() => {
                    this.loading = false;
                    console.log("reconnected")
                    this.messageHubAfterStart();
                })

                this.messageHub.start().then(() => {
                    this.messageHubAfterStart();
                }).catch(e => {
                    this.validating = true;
                    this.loading = false;
                    this.validationMessage = "Error Loading Messages";
                    console.error(e);
                });


                
            },
            messageHubAfterStart() {
                this.messageHub.invoke("joinGroup", this.conversationId.toString())
                .then(() => {
                    this.messageHub.invoke("getExistingMessages", this.conversationId.toString()).catch(e => { console.error(e.stack); console.log(e.name) });
                }).catch(e => console.error(e));
            },
            addedToGroup(group) {
                console.log(group);
            },
            showExistingMessages(messages) {
                this.messages = messages;
                this.loading = false;
            },
            broadcastMessage(messages) {
                for (let i = 0; i < messages.length; ++i) {
                    this.messages.push(messages[i]);

                    if (this.dropletSound) {
                        this.dropletSound.play();
                    }
                }
            },
            setAgentName(name) {
                console.log(name)
                this.agentName = name;
            },
            communicateGetNewNumber(){
                // called during Genesys chats when user wants to text to a new number
                if (this.messageHub) this.messageHub.stop();
                window.location.href = '/purecloud/?lineid=' + this.$route.query['LineId']
            },
            setMessageStatus(messageId, status) {
                var index = this.messages.findIndex(m => m.messageId === messageId);
                if (index > -1) {
                    this.messages[index].messageStatus = status;
                }
            },
            sendMessage() {
                if (this.messageText) {
                    this.messageHub.invoke("sendMessageCTS", this.agentName, this.messageText, this.conversationId);

                    if (this.emojisVisible) {
                        this.toggleEmojisVisible();
                    }
                }

                if (this.attachments && this.attachments.length > 0) {
                    const formData = new FormData();

                    formData.append("Wiid", this.conversationId);
                    for (let i = 0; i < this.attachments.length; ++i) {
                        formData.append("Files", this.attachments[i]);
                    }

                    fetch('/api/conversations/v1/upload-file', {
                        method: 'post',
                        body: formData
                    });

                    if (this.filePickerVisible) {
                        this.toggleFilePickerVisible();
                    }
                }

                this.messageText = "";
                this.attachments = [];
            },
            scrollChatToBottom() {
                let container = this.$el.querySelector("#chat-body");
                if (container) {
                    this.scrollTo(container, container.scrollHeight, 750);
                }
            },
            toggleEmojisVisible() {
                this.emojisVisible = !this.emojisVisible;
                this.filePickerVisible = false;
                this.attachments = [];

                if (this.emojisVisible) {
                    this.scrollChatToBottom();
                }
            },
            selectEmoji(emoji) {
                this.messageText += emoji.data;
            },
            toggleFilePickerVisible() {
                this.emojisVisible = false;
                this.filePickerVisible = !this.filePickerVisible;

                if (!this.filePickerVisible) {
                    this.attachments = [];
                }
                else {
                    this.scrollChatToBottom();
                }
            },
            fileAdded(files) {
                for (let i = 0; i < files.length; ++i) {
                    this.attachments.push(files[i]);
                }
            },
            fileRemoved(index) {
                this.attachments.splice(index, 1);
            },
            scrollTo(element, to, duration) {
                if (duration > 0) {
                    var difference = to - element.scrollTop;
                    var perTick = difference / duration * 10;

                    setTimeout(this.incrementScroll(element, to, duration, perTick), 10);
                }
            },
            incrementScroll(element, to, duration, perTick) {
                element.scrollTop = element.scrollTop + perTick;
                if (element.scrollTop !== to) {
                    this.scrollTo(element, to, duration - 10);
                }
            },
            hideHistoryMenu() {
                setTimeout(() => this.historyMenuActive = false, 100);
            },
            navigateToChatHistory(convId){
                window.open(`/chat-history?conversationId=${convId}&shaconv=${convId}`, '_blank')
            },
            navigateToCSV(convId){
                window.open(`./api/conversations/v1/export-history?conversationId=${convId}&shaconv=${convId}`)
            }
        }
    }
</script>

<style scoped>
    #parent {
        max-width: 35rem;
        height: 100%;
    }

    #chat {
        display: flex;
        flex-flow: column;
        height: 100%;
    }

    #chat-header {
        flex: 0 1 auto;
    }

    #chat-body {
        overflow-y: scroll;
        border-right: 0;
        flex: 1 1 auto;
    }

    #chat-footer {
        flex: 0 1 40px;
    }

    #header-icon {
        padding: 0rem 0.5rem;
    }

    #emoji-picker {
        width: 100%;
    }

    .center {
        margin: 0 auto;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 85%;
        margin: 0 auto;
    }

    .is-full-width {
        width: 100%;
    }

    .is-64 {
        width: 64px;
    }

    .input[disabled]::placeholder {
        color: rgba(54,54,54,.3);
    }

    .input[disabled] {
        background-color: #fcfcfc;
        border-color: #dbdbdb;
    }
</style>
