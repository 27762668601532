<template>
    <div class="container pt-10 mt-10" id="pccommunicate_div">
        
        <div class="container">
            <figure class="image is-90 is-centered">
                <img src="/images/textel_logo_90.png" />
            </figure>

            <phoneNumberPicker :numbers="other_lines" 
                :is_active="show_modal" @hideModal="openNumberPicker" />

            <h2 class="subtitle mt-4">Genesys Communicate SMS 
                <p v-if="me.name">
                    Hi, {{me.name}}!
                </p>
                <p v-if="my_line && my_line.lineID > 0 " 
                    class="has-text-weight-bold is-bold">
                    You Are Sending from {{my_line.phoneNumber | phone_format }} 
                </p>
                <span v-else>
                    <br />
                    <font-awesome-icon icon="spinner" class="fa fa-spin pt-10" size="lg" />
                </span>
                <p v-if="other_lines.length" class="">
                    <a class="button is-small is-link is-light"
                        @click="openNumberPicker">
                        <font-awesome-icon icon="exchange-alt" size="sm" class="mr-3"/> 
                        Use a different number
                    </a>
                </p>
            </h2>

            <div id="select-phone" v-if="my_line && my_line.lineID > 0 ">
                <div class="box" v-if="!is_new_conv">

                    <div class="field">
                        <label class="label">To phone number</label>
                        <div class="control has-icons-left">
                            <input class="input" type="text" placeholder="Enter phone number" 
                                v-model="to_phone_number_entry" 
                                @keyup.enter="search_number">
                            <span class="icon is-small is-left">
                                <font-awesome-icon icon="phone" />
                            </span>
                            <span v-if="!is_phone_valid" class="help is-danger has-text-right">
                                Invalid phone number
                            </span>
                        </div>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button class="button is-link is-fullwidth" 
                                :disabled="outstanding_ajax_requests > 0"
                                @click="search_number">
                                <font-awesome-icon icon="paper-plane" size="sm" class="mr-3"/> 
                                Text this Number
                            </button>
                            <button class="button is-light m-1"
                                @click="is_show_address_book = !is_show_address_book">
                                <font-awesome-icon icon="address-book" size="sm" class="mr-3" />
                                Address Book
                            </button>
                        </div>
                    </div>

                </div>

                <div v-if="is_new_conv" class="box">
                    <div class="field">
                        <label class="label">Send your first message to {{to_phone_number_entry | phone_format}}</label>
                        <textarea  class="textarea" v-model="first_message_body"
                            placeholder="Enter your message...">
                        </textarea>
                    </div>
                    <div class="field">
                        <button class="button is-link is-left" 
                            @click="send_first_message">Send!
                        </button>
                        <button class="button is-link is-light" 
                            @click="reset_page">Cancel
                        </button>
                    </div>
                </div>


                <div v-if="message_history.length < 1" class="panel is-bordered box">
                    <span v-if="outstanding_ajax_requests > 0">
                        Loading messages... 
                    </span>
                    <span v-else>
                        No messages found.
                    </span>
                </div>

                <div v-if="message_history.length && !is_new_conv" 
                    class="panel has-text-left is-bordered box">

                    <div class="panel-heading has-background-link has-text-white has-text-centered">
                        Recent Message History
                        <br />
                        <a href="#" @click.prevent="get_message_history" 
                            class="has-text-white is-size-6 has-text-weight-light">
                            <font-awesome-icon icon="sync" size="sm" /> 
                            Refresh
                        </a>
                    </div>

                    <a :class='{"panel-block": true, " has-background-info-light": m.incoming == 1 }'
                        :href="create_chat_url(m.conversationGuid, m.client)" 
                        v-for="m in message_history_current_view" 
                        :key="m.messageTimeStamp"> 

                        <div>

                            <span v-if="m.incoming == 0" 
                                :title="m.phoneNumber"
                                class="has-text-weight-semibold ">
                                Sent to {{m | display_filter}}:
                            </span>
                            <span v-else 
                                :title="m.phoneNumber"
                                class="has-text-weight-bold  has-text-link ">
                                <font-awesome-icon icon="exclamation-circle" size="sm" 
                                    class="mr-1"/> 
                                Received from {{m | display_filter}}: 
                            </span>

                            <font-awesome-icon icon="user-plus" size="sm"
                                v-if="m.client == m.displayName"
                                @click.prevent="searchAddressBook(m.client)"
                                class="has-text-grey-light has-text-right"
                                title="Click to add this contact">
                            </font-awesome-icon>

                            <span class="ml-3">
                                {{m.body}}
                            </span>

                            <em class="ml-2 has-text-weight-light is-size-7">
                                {{ m.lastActivity | date_format(tz_offset) }}
                            </em>

                        </div>

                    </a>

                    <hr />

                    <nav class="pagination" role="navigation" aria-label="pagination">
                        <a class="pagination-previous"
                            :disabled="message_history_current_page <= 1" 
                            @click="onPaginationPrev">
                            &lt; Newer conversations </a>
                        <a class="pagination-next"
                            :disabled="message_history_current_page >= message_history_last_page "
                            @click="onPaginationNext">
                            Older conversations ></a>
                    </nav>

                </div>

            </div>  

            <div class="box" v-if="is_error || my_line.err && my_line.err.length">
                <p class="is-center is-error" >
                    <strong v-html="my_line.err"></strong>
                    <br />
                    Please contact Textel for more information.
                </p>
            </div>

        </div>

        <address-book 
            :is_active="is_show_address_book"  
            :search_term="address_book_search_term"
            @phoneNumberSelected="search_number" 
            @closeAddressBook="onHideAddressBook" />
    </div>
</template>

<script>
import moment from 'moment';
import phoneNumberPicker from './PhoneNumberPicker.vue';
import addressBook from './AddressBook.vue'
import phone_format from '../utils/phoneNumbers'


export default {
  components: { phoneNumberPicker, addressBook },
    name: 'pc-communicate',

    data: function(){
        return{
            me: {},
            my_line: {},
            message_history: [],
            to_phone_number_entry: "",
            is_error: false,
            is_phone_valid: true,
            is_new_conv: false,
            outstanding_ajax_requests: 0,
            first_message_body: "",

            other_lines: [],
            show_modal: false,

            tz_offset: 0,

            message_history_per_page: 5,
            message_history_current_page: 1,
            message_history_current_view: [],

            is_show_address_book: false,
            address_book_search_term: "",

        }
    },
    filters: {

        phone_format(phoneNumberString) {
            return phone_format.to_human(phoneNumberString)
        },

        date_format(datetime, tz_offset){
            return moment(datetime).subtract({'minutes': tz_offset}).format('MMMM Do YYYY, h:mm:ss a')
        },

        display_filter(m){
            if (String(m.client) == String(m.displayName)){
                return phone_format.to_human(m.displayName)
            }else {
                return m.displayName
            }
        }
    },
    computed: {
        message_history_last_page(){
             return Math.ceil(this.message_history.length / this.message_history_per_page)
        }
    },
    methods:{

        create_chat_url(conversationGuid, externalNumber){
            return '/chat?IsSidebar=true&appcontext=purecloud&conversationId=' + conversationGuid + '&MobileNumber=' + externalNumber + "&LineId=" + this.my_line.lineGuid
        },
        authenticate_user(){
            window.location.href = '/api/purecloud/v1/auth?state=' + this.$route.query['lineid']
        },
        fetch_user_details(token){
            this.outstanding_ajax_requests++
            fetch('/api/purecloud/v1/user-info/?pcToken=' + token + '&lineid=' + this.$route.query['lineid'])
            .then( resp =>  resp.json() )
            .then( d => {
                if (d.error){
                    this.is_error = true;
                    this.my_line.err = d.error
                    throw new Error("Failed Auth")
                }
                this.me = d
            })
            .then( () => this.validate_line(this.me) )
            .catch(e => {
                console.log(e)
                 this.is_error = true
            })
            .finally(() => this.outstanding_ajax_requests--)
        },

        validate_line(user){
            const lineguid = this.$route.query['lineid']

            this.outstanding_ajax_requests++
            fetch('/api/purecloud/v1/get-line-info/?lineid=' + lineguid + '&orgid=' + user.OrgId + '&pcToken=' + this.$route.query['pcToken'])
            .then( resp =>  resp.json() )
            .then( d => {
                this.my_line = d
                this.other_lines = d.otherLines
             } )
            .then( () => this.get_message_history() )
            .catch(e => {
                console.log(e)
                 this.is_error = true
            })
            .finally(() => this.outstanding_ajax_requests--)
        },
        search_number(to_number){

            // ugly, but someimtes we need to pass in number
            if (typeof(to_number) == 'undefined' || typeof(to_number) == 'object'){
                to_number = this.to_phone_number_entry
            }

            if (this.to_phone_number_entry == "" && to_number != ""){
                this.to_phone_number_entry = to_number
            }

            // strip out non-numeric chars
            to_number = phone_format.to_clean(to_number)

            if (to_number.length < 10){
                return false
            }

            this.is_phone_valid = true
            this.outstanding_ajax_requests++
            fetch('/api/purecloud/v1/search-number/?phonenumber=' + to_number + '&linenumber=' + this.my_line.phoneNumber + '&lineID=' + this.my_line.lineID)
            .then( resp =>  resp.json() )
            .then( d => {

                if (!d){
                    this.is_new_conv = true
                    return false;
                }

                // hack to keep the button disabled
                this.outstanding_ajax_requests++
                window.location.replace( this.create_chat_url(d.conversationGuid, to_number) )
            } )
            .catch(e => {
                console.log(e)
                this.is_error = true
                this.is_phone_valid = false
            } )
            .finally(() => this.outstanding_ajax_requests--)
        },
        get_message_history(){

            // prepare to fetch
            this.outstanding_ajax_requests++
            this.message_history = []

            fetch('/api/purecloud/v1/get-line-history/?lineid=' + this.my_line.lineID )
            .then( resp =>  resp.json() )
            .then( d => {
                this.message_history = d 
                this.message_history_current_page = 1
                this.message_history_current_view  = this.message_history.slice(0, (this.message_history_per_page ))
            })
            .catch(e => {
                console.log(e)
                this.is_error = true
            })
            .finally(() => this.outstanding_ajax_requests--)
        },
        send_first_message(){
            
            // set default
            this.is_phone_valid = true

            // if not a valid phone, exit now
            if (this.first_message_body.length < 1){
                this.is_phone_valid = false
                return false
            }

            fetch('/conversation/agentoutboundsend',{
                method: "POST",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                    LineId: this.my_line.lineGuid,
                    MessageBody: this.first_message_body.trim(),
                    ToPhoneNumber: this.to_phone_number_entry
                })
            }).then( resp => resp.json() )
            .then(d => {

                if (d.sentSuccessfully){
                    var to_phone = this.to_phone_number_entry
                    this.to_phone_number_entry = ""
                    this.first_message_body = ""
                    this.is_new_conv = false
                    this.search_number(to_phone)
                } else {
                    this.is_error = true
                }
    
            }).catch( e => {
                console.log(e)

            }).finally( () => {
                this.first_message_body = ""
            })
        },
        reset_page(){
            this.to_phone_number_entry = ""
            this.is_new_conv = false
            this.is_error = false
        },
        onPaginationNext(){

            var next_page = this.message_history_current_page + 1
            var start_with_msg = this.message_history_current_page * this.message_history_per_page

            if (next_page <= this.message_history_last_page) {
                this.message_history_current_page = next_page
                this.message_history_current_view = this.message_history.slice(start_with_msg, next_page * this.message_history_per_page )
            }

        },
        onPaginationPrev(){
            var prev_page = this.message_history_current_page - 1
            var start_with_msg = (prev_page -1) * this.message_history_per_page

            if (prev_page >= 1){
                this.message_history_current_page = prev_page
                this.message_history_current_view = this.message_history.slice(start_with_msg, prev_page * this.message_history_per_page )
            }
        },
        openNumberPicker(){
            this.show_modal = !this.show_modal
        },
        onShowAddressBook(){
            this.is_show_address_book = true
        },
        onHideAddressBook(){
            this.is_show_address_book = false
            this.address_book_search_term = ""
            this.get_message_history()
        },
        searchAddressBook(phoneNumber){
            this.address_book_search_term = phoneNumber
            this.onShowAddressBook()
        }
    },
    mounted: function(){
        this.tz_offset  = new Date().getTimezoneOffset();

        if ( this.$route.query['pcToken'] ){
            this.fetch_user_details(this.$route.query['pcToken'])
        } else {
            this.authenticate_user()
        }

    }
}
</script>


<style lang="css">

    #pccommunicate_div {
        text-align: center;
        padding-top: 3rem;
    }

    .image.is-centered {
        position: relative;
        margin: 0 auto;
    }

    .image.is-90 {
        width: 90px;
    }
</style>