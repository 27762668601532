<template>
    <div id="layout">
        <nav v-if="showNav" class="navbar has-shadow is-spaced" role="navigation" aria-label="main navigation">
            <div class="navbar-brand">
                <a class="navbar-item" href="/">
                    <img src="images/textel_logo_90.png">
                </a>

                <a role="button" v-on:click="toggleMenu" :class="navbarBurgerClass" aria-label="menu" aria-expanded="false"
                   data-target="navbarBasicExample">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div id="navbarBasicExample" :class="navbarMenuClass">
                <div class="navbar-start">
                    <a class="navbar-item" href="/">
                        Home
                    </a>

                    <a class="navbar-item" href="/conversation">
                        Conversations
                    </a>

                    <a class="navbar-item" href="/reports">
                        Reports
                    </a>

                    <div class="navbar-item has-dropdown is-hoverable">
                        <a class="navbar-link">
                            More
                        </a>

                        <div class="navbar-dropdown">
                            <a class="navbar-item">
                                About
                            </a>
                            <a class="navbar-item">
                                Contact
                            </a>
                            <hr class="navbar-divider">
                            <a class="navbar-item">
                                Report an issue
                            </a>
                        </div>
                    </div>
                </div>

                <div class="navbar-end">
                    <div class="navbar-item">
                        <div class="buttons">
                            <a class="button is-info">
                                <strong>Sign up</strong>
                            </a>
                            <a class="button is-light">
                                Log in
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>

        <div class="is-full-height">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'app',
        data() {
            return {
                isMenuActive: false,
                navbarBurgerClassDefault: 'navbar-burger burger',
                navbarMenuClassDefault: 'navbar-menu',
                navbarBurgerClass: 'navbar-burger burger',
                navbarMenuClass: 'navbar-menu',
                showNav: false
            };
        },
        methods: {
            toggleMenu() {
                this.isMenuActive = !this.isMenuActive;

                if (this.isMenuActive) {
                    this.navbarBurgerClass = this.navbarBurgerClassDefault + " is-active";
                    this.navbarMenuClass = this.navbarMenuClassDefault + " is-active";
                }
                else {
                    this.navbarBurgerClass = this.navbarBurgerClassDefault;
                    this.navbarMenuClass = this.navbarMenuClassDefault;
                }
            }
        }
    }
</script>

<style>
    html, body {
        height: 100%;
        overflow-y: auto;
    }

    .is-full-height {
        height: 100%;
    }

    #layout {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        height: 100%;
    }
</style>
