<template>
    <div id="history" class="container">
        <div v-if="loading" class="has-text-centered">
            <section class="hero is-fullheight">
                <div class="hero-body">
                    <div class="container">
                        <span class="has-text-info">
                            <font-awesome-icon icon="spinner" size="2x" pulse />
                        </span>
                    </div>
                </div>
            </section>
        </div>
        <div v-else-if="error" class="has-text-centered">
            <section class="hero is-fullheight">
                <div class="hero-body">
                    <div class="container">
                        <p class="title">
                            {{ error }}
                        </p>
                    </div>
                </div>
            </section>
        </div>
        <div v-else>
            <img src="images/textel_logo_90.png" width="128" alt="Textel Logo" />

            <article class="message is-light mt-3">
                <div class="message-header">
                    <p class="title is-4 my-0">Conversation History</p>
                    <a class="button is-info" role="button" :href="'./api/conversations/v1/export-history?conversationId=' + conversationId + '&shaconv=' + conversationId">
                        <span class="icon">
                            <font-awesome-icon icon="file-export" />
                        </span>
                        <span>Export All to CSV</span>
                    </a>
                </div>
                <div class="message-body">
                    <p><strong>Account:</strong> {{ account }}</p>
                    <p><strong>Line:</strong> {{ line }}</p>
                </div>
            </article>

            <article v-for="(messageGroup, index) in messagesByConvId" class="message is-info" :key="'messageGroup' + index">
                <div class="message-header">
                    <p>
                        Agent: <span class="has-lighter-weight">{{ messageGroup[0].iC_AgentID }}: {{ messageGroup[0].iC_AgentFirstName }}, {{ messageGroup[0].iC_AgentLastName }}</span>
                        <br />
                        Start Date: <span class="has-lighter-weight">{{ adjustForTimezone(messageGroup[0].startDateTime) }}</span>
                        <br />
                        End Date: <span class="has-lighter-weight">{{ adjustForTimezone(messageGroup[0].startDateTime) }}</span>
                        <br />
                        Between: <span class="has-lighter-weight">{{ formatPhoneNumber(messageGroup[0].source) }} and {{ formatPhoneNumber(messageGroup[0].destination) }}</span>
                        <br />
                        Work Item Id: <span class="has-lighter-weight">{{ messageGroup[0].workItemId }}</span>

                        <br />

                        <span>
                            <a class="button is-small mr-1 mt-2" role="button" :href="'./api/conversations/v1/export-history?conversationId=' + messageGroup[0].workItemId + '&type=csv'">
                                <span class="icon">
                                    <font-awesome-icon icon="file-export" />
                                </span>
                                <span>Export To CSV</span>
                            </a>

                            <a class="button is-small ml-1 mt-2" role="button" :href="'./api/conversations/v1/export-history?conversationId=' + messageGroup[0].workItemId  + '&type=txt'">
                                <span class="icon">
                                    <font-awesome-icon icon="file-export" />
                                </span>
                                <span>Export To TXT</span>
                            </a>
                        </span>
                    </p>
                </div>

                <div class="message-body px-1 py-1">
                    <div class="table-container">
                        <table class="table is-fullwidth is-striped">
                            <thead>
                                <tr>
                                    <th>Index</th>
                                    <th>Body</th>
                                    <th><abbr title="UTC Time Stamp">UTC</abbr></th>
                                    <th><abbr title="Direction">Dir</abbr></th>
                                    <th><abbr title="Attachment"><font-awesome-icon icon="paperclip" size="sm" /></abbr></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(message, index) in messageGroup" :key="'message' + index">
                                    <th>{{ index }}</th>
                                    <td class="is-multiline">{{ message.body }}</td>
                                    <td>{{ message.utcTimeStamp.toLocaleString() }}</td>
                                    <td>{{ message.messageType === 'TO' ? 'Outbound' : 'Inbound' }}</td>
                                    <td v-if="message.hasAttachment"><a :href="message.url" target="_blank">View Attachment</a></td>
                                    <td v-else></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </article>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'History',
        data() {
            return {
                loading: true,
                conversationId: "",
                error: "",
                account: "",
                line: "",
                messagesByConvId: [],
            }
        },
        mounted() {
            this.getHistory();
        },
        methods: {
            getHistory() {
                var queryString = Object.keys(this.$route.query).map(key => key + '=' + this.$route.query[key]).join('&');

                fetch("api/conversations/v1/get-history?" + queryString)
                    .then(response => response.json())
                    .then(data => {
                        this.conversationId = this.$route.query['conversationid'] || this.$route.query['conversationId'];
                        this.error = "";
                        this.messagesByConvId = this.groupBy(data, "convId");
                        this.loading = false;

                        if (data && data.length > 0) {
                            this.account = data[0].aName;
                            this.line = data[0].lName;
                        }
                    }).catch(e => {
                        console.error(e);
                        this.error = "Error Loading History";
                        this.loading = false;
                    });
            },
            adjustForTimezone(date) {
                let timeOffsetInMS = new Date().getTimezoneOffset() * 60000;
                return new Date(new Date(date).getTime() - timeOffsetInMS).toLocaleString();
            },
            groupBy(xs, key) {
                return xs.reduce(function (rv, x) {
                    (rv[x[key]] = rv[x[key]] || []).push(x);
                    return rv;
                }, {});
            },
            formatPhoneNumber(phoneNumberString) {
                var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
                var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
                if (match) {
                    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
                }
                return phoneNumberString;
            }
        }
    }
</script>

<style scoped>
    #history {
        padding: 2rem;
    }

    .has-lighter-weight {
        font-weight: lighter !important;
    }

    is-multiline {
        white-space: pre;
    }
</style>
