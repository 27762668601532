<template>

<div class="modal" :class="{'modal': true, 'is-active': is_active}">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title has-text-left">
          Address Book
        <font-awesome-icon icon="spinner"
            v-if="outstanding_ajax_requests > 0"
            class="fa fa-spin pt-10" size="sm" />
      </p>
      <button class="delete" aria-label="close" @click="onCloseWindow"></button>
    </header>
    <section class="modal-card-body">
 
        <div class="field" v-if="!is_show_add_form">
                <label class="label">Search by phone number or name </label>
                <div class="control has-icons-left">
                    <input 
                        class="input has-icons-right" type="text" 
                        placeholder="Search by name or phone number"
                        @keyup="searchGenesys"
                        v-model="search_input" />

                <span class="icon is-small is-left">
                    <font-awesome-icon icon="address-book" size="sm" class="mr-3" />
                </span>
            </div>
                <p class="mt-2"><small>
                    Results include your Genesys Cloud External contacts as well as any contacts you've 
                    already saved with Textel.</small>
                </p>

        </div>

        <div class="field control" v-if="is_show_add_form">

            <div class="columns">

                <div class="field column">
                    <label class="label">Phone Number</label>
                    <div class="control has-icons-left">
                        <input class="input" type="text" 
                            placeholder="Enter a phone number" 
                            v-model="addNew_phoneNumber">
                        <span class="icon is-small is-left">
                            <font-awesome-icon icon="phone" class="mr-3"/>
                        </span>
                    </div>
                    <p class="help is-danger has-text-right" v-if="is_add_contact_error">
                        Valid phone number is required
                    </p>
                </div>

                <div class="field column">
                    <label class="label">First Name</label>
                    <div class="control has-icons-left">
                        <input class="input" type="text" 
                            placeholder="Enter a first name" 
                            v-model="addNew_firstName">
                        <span class="icon is-small is-left">
                            <font-awesome-icon icon="user" class="mr-3"/>
                        </span>
                    </div>
                </div>

                <div class="field column">
                    <label class="label">Last Name</label>
                    <div class="control has-icons-left">
                        <input class="input" type="text" 
                            placeholder="Enter a last name" 
                            v-model="addNew_lastName">
                        <span class="icon is-small is-left">
                            <font-awesome-icon icon="user" class="mr-3"/>
                        </span>
                    </div>
                </div>

            </div>

        </div>

        <div v-if="contact_list_lookup.length" class="">
            <hr>
            <h2 class="mb-3"><strong> Select a contact </strong></h2>

            <button class="is-fullwidth button is-light is-link m-3"
                @click="selectContact(c)"
                v-for="c in contact_list_lookup" :key="c.phoneNumber">
                 <font-awesome-icon icon="phone" class="mr-3"/>
                Text with <strong class="ml-3 mr-3">{{c.fullName}}</strong>  ({{c.phoneNumber | phone_format}})
                <!-- <br />
                <small class="tag">{{c.source}} CONTACT</small> -->
            </button>

        </div>
        <div>
            <font-awesome-icon icon="spinner"
            v-if="outstanding_ajax_requests > 0"
            class="fa fa-spin pt-20" size="lg" />
        </div>
        <div v-if="is_no_results_found && !is_show_add_form">
            <p class="title is-4 notification">No results found.  
                <a href="#" @click="addNew">
                    Add a new contact!
                </a>
            </p>
        </div>

    </section>
    <footer class="modal-card-foot"  v-if="!is_show_add_form">
      <button class="button is-link" @click="addNew" >Add New Contact</button>
      <button class="button" @click="onCloseWindow">Close</button>
    </footer>
    <footer class="modal-card-foot"  v-if="is_show_add_form">
      <button class="button is-link"  @click="saveNewContact">Save</button>
      <button class="button" @click="addNewClose">Cancel</button>
    </footer>
  </div>
</div>

</template>

<script>
import phone_format from '../utils/phoneNumbers'
import _ from 'lodash';


export default {
    name: "address-book",
    props: [ 'is_active', 'search_term'],
    watch: {
        is_active(n){
            // hack to fire the search when string is passed into the componenet.
            // the updated hook was firing repeatedly for some reason
            if(n===true){
                this.search_input = String(this.search_term)
                this.searchGenesys()
            }
        },
        addNew_phoneNumber(n){
            if (n.length){ 
                this.is_add_contact_error = false
            }
        }
    },
    components: {  },
    filters: {
        phone_format(phoneNumberString) {
            return phone_format.to_human(phoneNumberString)
        }
    },
    data(){
        return{
            outstanding_ajax_requests: 0,
            search_input: "",
            contact_list_lookup: [],

            is_show_add_form: false,
            addNew_firstName: "",
            addNew_lastName: "",
            addNew_phoneNumber: "",
            is_no_results_found: false,
            is_add_contact_error: false
        }
    },

    methods: {

        searchGenesys: _.debounce(function () {

            if (String(this.search_input).trim().length < 2) return 
            this.outstanding_ajax_requests++
            this.is_no_results_found = false

            fetch('/api/purecloud/v1/search-contacts?pcToken=' + this.$route.query['pcToken'] + "&lineGuid=" + this.$route.query['lineid'] + '&query=' + this.search_input.trim())
            .then( resp =>  resp.json() )
            .then( d => {
                this.contact_list_lookup = d
                if (d.length == 0){
                    this.is_no_results_found = true
                }
            })
            .finally( ()=> this.outstanding_ajax_requests-- )
        }, 500),

        selectContact(item) {
 
            if (item) {

                // give user the feeling that something is happening
                this.outstanding_ajax_requests++
                this.contact_list_lookup = []

                // if a genesys contact, save it!
                if (item.source == 'GENESYS'){

                    this.addNew_lastName = item.lastName
                    this.addNew_firstName = item.firstName
                    this.addNew_phoneNumber = String(item.phoneNumber)

                    this.saveNewContact(true)
                }

            }

            // this will trigger the conversation load (done at parent level)
            this.$emit('phoneNumberSelected', String(item.phoneNumber))

            this.onCloseWindow()
        },

        onCloseWindow(){
            this.contact_list_lookup = []
            this.is_no_results_found = false
            this.search_input = ""
            this.addNewClose()
            this.$emit('closeAddressBook')
        },

        addNew(){
            this.is_show_add_form = true
            this.is_no_results_found = false
            this.contact_list_lookup = []

            if ( phone_format.to_clean( this.search_input).length == 10 ){
                this.addNew_phoneNumber = this.search_input
            }
        },

        addNewClose(){
            this.addNew_firstName = ""
            this.addNew_lastName = ""
            this.addNew_phoneNumber = ""
            this.is_show_add_form = false
            this.is_no_results_found = false
            this.search_input = ""
        },

        saveNewContact(bypass = false){

            if (typeof bypass == 'object'){

                if (phone_format.to_clean(this.addNew_phoneNumber).length != 10 ) {
                    this.is_add_contact_error = true
                    return false
                }
            }

            this.outstanding_ajax_requests++
            fetch('/api/purecloud/v1/contact',{
                method: "POST",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                    lastName: this.addNew_lastName,
                    firstName: this.addNew_firstName,
                    phoneNumber: phone_format.to_clean(this.addNew_phoneNumber),
                    lineGuid: this.$route.query['lineid']
                })
            }).then( resp => resp.json() )
            .then(d => {
                if (d.success){

                    // trickery to go back to search form
                    let temp = this.addNew_phoneNumber
                    this.addNewClose()
                    this.search_input = temp
                    this.searchGenesys()

                }
            }).finally( () =>{
                this.outstanding_ajax_requests--
            })
        }

    },

}
</script>

<style scoped>

    .card {
        cursor: pointer;
    }
</style>