<template>
    <div class="file-picker">
        <div class="file-picker-background"
             :class="{ 'file-picker-active': isActive }"
             @dragover="setActive"
             @dragleave="cancelActive"
             @drop="fileAdded">

            <font-awesome-icon id="filePdf" class="is-hidden" icon="file-pdf" size="4x" />
            <font-awesome-icon id="fileGeneric" class="is-hidden" icon="file-alt" size="4x" />

            <div v-if="!files || files.length === 0" class="level is-full-height is-mobile">
                <div class="level-item has-text-centered has-text-primary">
                    <div>
                        <p class="has-text-primary">
                            <font-awesome-icon icon="upload" size="2x" />
                        </p>
                        <p class="has-text-grey mt-2">
                            Drop a file here or Browse below
                        </p>
                        <div class="mt-2">
                            <input id="vfp-filePicker"
                                   class="vfp-inputfile vfp-gridItem is-hidden"
                                   type="file"
                                   ref="browse"
                                   name="vfp-filePicker"
                                   :accept="accept"
                                   :multiple="allowMultiple"
                                   @change="fileAdded" />
                            <a class="button" @click="$refs.browse.click()" role="button">
                                Browse
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="scrollable mx-5 my-5">
                <div class="columns is-multiline is-mobile">
                    <div v-for="(file, index) in files" :key="index" class="column is-4">
                        <div class="card">
                            <header class="has-text-right pr-1 pt-1">
                                <button class="delete is-small" aria-label="close" @click="removeFile(index)"></button>
                            </header>

                            <div class="card-image preview-tile">
                                <figure :id="'preview' + index" class="tile-image">
                                </figure>
                            </div>

                            <footer class="card-footer">
                                <p class="file-text">{{ file.name }}</p>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FilePicker',
        props: {
            accept: {
                type: String,
                default: "image/*"
            },
            allowMultiple: {
                type: Boolean,
                default: true
            },
            files: {
                type: Array,
                default: null
            },
        },
        data: function () {
            return {
                isActive: false
            }
        },
        computed: {
            requiresTypeCheck: function () {
                return this.accept !== '*/*'
            },
            acceptedTypes: function () {
                return this.accept.split(',')
            }
        },
        updated() {
            for (let i = 0; i < this.files.length; ++i) {
                this.previewFile(i);
            }
        },
        methods: {
            cancelHandlers(e) {
                e.preventDefault()
                e.stopPropagation()
            },
            setActive(e) {
                this.isActive = true
                this.cancelHandlers(e)
            },
            cancelActive(e) {
                this.isActive = false
                this.cancelHandlers(e)
            },
            fileAdded(e) {
                this.isActive = false
                this.cancelHandlers(e)
                const wasDropped = e.dataTransfer
                const files = wasDropped ? e.dataTransfer.files : e.target.files
                if (wasDropped && !this.allowMultiple && files.length > 1) throw new Error('Multiple files are not allowed.')
                if (wasDropped && this.requiresTypeCheck) {
                    for (var i = 0; i < files.length; i++) {
                        if (this.acceptedTypes.indexOf(files[i].type) === -1) throw new Error(' File type not allowed.')
                    }
                }

                this.$emit('file-added', files);
            },
            previewFile(index) {
                let element = document.getElementById('preview' + index);
                let fileName = this.files[index].name.toLowerCase();

                element.querySelectorAll('*').forEach(e => e.remove());

                if (fileName.indexOf('.jpg') !== -1  ||
                    fileName.indexOf('.jpeg') !== -1 ||
                    fileName.indexOf('.png') !== -1  ||
                    fileName.indexOf('.bmp') !== -1  ||
                    fileName.indexOf('.gif') !== -1) {
                    let reader = new FileReader();
                    reader.readAsDataURL(this.files[index]);
                    reader.onloadend = function () {
                        let img = document.createElement('img');
                        img.src = reader.result;
                        element.appendChild(img);
                    }
                }
                else if (fileName.indexOf('.pdf') !== -1) {
                    let icon = document.getElementById("filePdf").cloneNode(true);
                    icon.id = "";
                    icon.classList.remove("is-hidden");
                    element.appendChild(icon);
                }
                else {
                    let icon = document.getElementById("fileGeneric").cloneNode(true);
                    icon.id = "";
                    icon.classList.remove("is-hidden");
                    element.appendChild(icon);
                }
            },
            removeFile(index) {
                this.$emit('file-removed', index);
            }
        }
    }
</script>

<style>
    .file-picker {
        display: flex;
        height: 430px;
        width: 100%;
    }

    .file-picker-active {
        background-color: #c8e5f8;
        outline-color: #f2f5f7;
    }

    .is-full-height {
        height: 100%;
    }

    .file-picker-background {
        transition: 0.3s;
        background: #f7f9fa;
        width: 100%;
        outline: 2px dashed #CACFD2;
        outline-offset: -10px;
        color: #3b3e40;
        text-align: center;
    }

    .scrollable {
        height: 380px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .preview-tile {
        padding-top: 1rem;
        height: 100px;
        text-align: center;
    }

        .preview-tile img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            display: block;
            max-width: 100px;
            max-height: 80px;
            width: auto;
            height: auto;
        }

    .file-text {
        padding: 0.5rem;
        text-align: center;
        text-overflow: ellipsis;
        overflow-x: hidden;
    }
</style>
