export default {
    /**
     * Formats phone number XXXXXXXXXX to (XXX) XXX-XXXX
     *
     * @param {string} phoneNumberString - phone number
     * @returns {string} - formatted phone number
     */
     to_human(phoneNumberString) {
        var cleaned = String(phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        } else {
            var match_us = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/)
            return (match_us) ? '(' + match_us[2] + ') ' + match_us[3] + '-' + match_us[4] : null
        }

    },

    /***
     * Formats phone number as 10 digit integer XXXXXXXXXX
     *
     * @param {string} dirtyString - phone number which might contain spaces, dahes, parentheses, etc
     * @return {string} - formatted phone number
     */
    to_clean(dirtyString){
        return String(dirtyString).replace ( /[^0-9]/g, '' );
    }
}