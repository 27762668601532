import 'bulma/css/bulma.min.css';

import Vue from 'vue'
import VueRouter from 'vue-router';
import App from './App.vue'
import Home from './components/Home.vue';
import AgentOutbound from './components/AgentOutbound.vue';
import StartChat from './components/StartChat';
import Chat from './components/Chat.vue';
import ChatHistory from './components/ChatHistory.vue';
import AccountSummary from './components/AccountSummary.vue';
import Login from './components/Login.vue';
import StartGroup from './components/StartGroup.vue';
import PcCommunicate from './components/PcCommunicate.vue';
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faSpinner, faAngleDown, faHistory, faFileExport, faPaperPlane, faMobileAlt, faUpload, faFilePdf, faFileAlt, faCheck,
    faTimes, faBan, faPaperclip, faUser, faLock, faPhone, faArrowLeft, faPlus, faExclamationCircle, faSync, faExchangeAlt,
    faAddressBook, faUserPlus
} from '@fortawesome/free-solid-svg-icons'
import { faSmile, faImage, faClock } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faSpinner, faAngleDown, faHistory, faFileExport, faPaperPlane, faSmile, faImage, faClock, faMobileAlt, faUpload,
    faFilePdf, faFileAlt, faCheck, faTimes, faBan, faPaperclip, faUser, faLock, faPhone, faArrowLeft, faPlus, faExclamationCircle, faSync, faExchangeAlt,
    faAddressBook, faUserPlus)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueRouter);
Vue.use(require('vue-moment'));

new Vue({
    el: '#app',
    render: h => h(App),
    router: new VueRouter({
        mode: 'history',
        routes: [
            { path: '/', component: Home },
            { path: '/chat/new', component: StartChat },
            { path: '/chat', component: Chat },
            { path: '/purecloud', component: PcCommunicate}, 
            { path: '/conversation', component: Chat },
            { path: '/conversation/sidebar', component: Chat },
            { path: '/chat-history', component: ChatHistory },
            { path: '/account-summary', component: AccountSummary },
            { path: '/login', component: Login },
            { path: '/conversation/agentoutbound', component: AgentOutbound },
            { path: '/conversation/startgroup', component: StartGroup },
            { path: '/api' },
            { path: '*', redirect: '/' }
        ]
    })
});
