<template>
    <div class="content">
        <div v-for="(message, index) in messages" :key="index">
            <div v-if="message.messageType === 'TO'" class="message-bubble is-pulled-right my-1">
                <div>
                    <p class="has-text-grey has-text-right my-0 mr-1">
                        <small :title="adjustForTimezone(message.messageTimeStamp)">
                            <font-awesome-icon :icon="['far', 'clock']" size="xs" />
                            {{ relativeTime(message.messageTimeStamp) }}
                        </small>

                        <span class="ml-1">
                            <small v-if="message.messageStatus === 0">
                                <font-awesome-icon icon="spinner" size="xs" pulse />
                            </small>
                            <small v-else-if="message.messageStatus === 4" class="has-text-success">
                                <font-awesome-icon icon="check" size="xs" />
                            </small>
                            <small v-else-if="message.messageStatus === 6" class="has-text-success">
                                <font-awesome-icon icon="check" size="xs" />
                            </small>
                            <small v-else-if="message.messageStatus === 5" class="has-text-danger">
                                <font-awesome-icon icon="times" size="xs" />
                            </small>
                            <small v-else-if="message.messageStatus === 7" class="has-text-danger">
                                <font-awesome-icon icon="times" size="xs" />
                            </small>
                            <small v-else-if="message.messageStatus === -1234" class="has-text-danger">
                                <font-awesome-icon icon="ban" size="xs" />
                            </small>
                        </span>
                    </p>

                    <p class="message-bubble-light has-text-white-bis has-background-info">
                        <strong v-if="message.agentInitials && message.agentInitials.length > 0" class="has-text-white-bis">
                            {{ message.agentInitials }}
                        </strong>
                        <strong v-else class="has-text-white-bis">
                            {{ agentName }}
                        </strong>

                        <small class="has-text-white-ter ml-1 has-text-vert-top">
                            <font-awesome-icon icon="phone" size="xs" />
                            {{ formatPhoneNumber(message.messageFrom) }}
                        </small>

                        <br />

                        <span>
                            {{ message.messageBody }}
                        </span>

                        <span v-if="message.messageAttachment && message.messageAttachment.length > 0" class="mt-3">
                            <a :href="message.messageAttachment" target="_blank">
                                <span v-if="attachmentIsImage(message.messageAttachment)" class="image mt-2">
                                    <img :src="message.messageAttachment" alt="Message Attachment" />
                                </span>
                                <span v-else>
                                    {{ message.messageAttachment.split('/').pop() }}
                                </span>
                            </a>
                        </span>
                    </p>
                </div>
            </div>
            <div v-else class="message-bubble is-pulled-left my-1">
                <div>
                    <p class="has-text-grey has-text-left my-0 ml-1">
                        <small :title="adjustForTimezone(message.messageTimeStamp)">
                            <font-awesome-icon :icon="['far', 'clock']" size="xs" />
                            {{ relativeTime(message.messageTimeStamp) }}
                        </small>

                        <span class="ml-1">
                            <small v-if="message.messageStatus === 4" class="has-text-success">
                                <font-awesome-icon icon="check" size="xs" />
                            </small>
                            <small v-else-if="message.messageStatus === 6" class="has-text-success">
                                <font-awesome-icon icon="check" size="xs" />
                            </small>
                            <small v-else-if="message.messageStatus === 5" class="has-text-danger">
                                <font-awesome-icon icon="times" size="xs" />
                            </small>
                            <small v-else-if="message.messageStatus === 7" class="has-text-danger">
                                <font-awesome-icon icon="times" size="xs" />
                            </small>
                            <small v-else-if="message.messageStatus === -1234" class="has-text-danger">
                                <font-awesome-icon icon="ban" size="xs" />
                            </small>
                        </span>
                    </p>

                    <p class="message-bubble-dark has-text-white-bis has-background-dark">
                        <strong v-if="message.mobileUserInitials && message.mobileUserInitials.length > 0" class="has-text-white-bis">
                            {{ message.mobileUserInitials }}
                        </strong>
                        <strong v-else class="has-text-white-bis">
                            {{ userName }}
                        </strong>

                        <small class="has-text-white-ter ml-1 has-text-vert-top">
                            <font-awesome-icon icon="mobile-alt" size="xs" />
                            {{ formatPhoneNumber(message.messageFrom) }}
                        </small>

                        <br />

                        <span>
                            {{ message.messageBody }}
                        </span>

                        <span v-if="message.messageAttachment && message.messageAttachment.length > 0" class="mt-3">
                            <a :href="message.messageAttachment" target="_blank">
                                <span v-if="attachmentIsImage(message.messageAttachment)" class="image mt-2">
                                    <img :src="message.messageAttachment" alt="Message Attachment" />
                                </span>
                                <span v-else>
                                    {{ message.messageAttachment.split('/').pop() }}
                                </span>
                            </a>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'ChatMessages',
        props: {
            agentName: {
                type: String,
                default: 'A'
            },
            userName: {
                type: String,
                default: 'C'
            },
            messages: {
                type: Array,
                default: null
            },
        },
        data() {
            return {
                isRenderUpdate: false,
                timer: null
            }
        },
        mounted() {
            this.$emit('scroll');
            this.timer = setInterval(this.renderUpdate, 30000);
        },
        updated() {
            if (this.isRenderUpdate) {
                this.isRenderUpdate = false;
            }
            else {
                this.$emit('scroll');
            }
        },
        beforeDestroy() {
            if (this.timer) {
                clearInterval(this.timer);
            }
        },
        methods: {
            attachmentIsImage(messageAttachment) {
                let attachmentLower = messageAttachment.toLowerCase();
                return attachmentLower.indexOf('.jpg') !== -1  ||
                       attachmentLower.indexOf('.jpeg') !== -1 ||
                       attachmentLower.indexOf('.png') !== -1  ||
                       attachmentLower.indexOf('.bmp') !== -1  ||
                       attachmentLower.indexOf('.gif') !== -1;
            },
            adjustForTimezone(date) {
                let timeOffsetInMS = new Date().getTimezoneOffset() * 60000;
                return new Date(new Date(date).getTime() - timeOffsetInMS).toLocaleString();
            },
            relativeTime(date) {
                var timeOffsetInMS = new Date().getTimezoneOffset() * 60000;
                var newDate = new Date(new Date(date).getTime() - timeOffsetInMS);
                return moment(newDate).startOf('minute').fromNow();
            },
            formatPhoneNumber(phoneNumberString) {
                var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
                var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
                if (match) {
                    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
                }
                return phoneNumberString;
            },
            renderUpdate() {
                this.isRenderUpdate = true;
                this.$forceUpdate();
            }
        }
    }
</script>

<style scoped>
    .message-bubble {
        max-width: 90%;
        clear: both;
        content: " ";
        position: relative;
        z-index: 0;
        word-break: break-word;
    }

        .message-bubble a {
            color: #965fc1;
            cursor: pointer;
            text-decoration: none;
        }

            .message-bubble a:hover {
                color: #8856b0;
                cursor: pointer;
                text-decoration: none;
            }

    .message-bubble-dark {
        z-index: 10;
        position: relative;
        background-color: #363636;
        border-radius: 6px;
        padding: 1rem;
        box-shadow: 0 .5em 1em -.125em rgba(10,10,10,.3),0 0 0 1px rgba(10,10,10,.02);
        -moz-box-shadow: 0 .5em 1em -.125em rgba(10,10,10,.3);
        -webkit-box-shadow: 0 .5em 1em -.125em rgba(10,10,10,.3);
    }

    .message-bubble-light {
        z-index: 10;
        position: relative;
        background-color: #3298dc;
        border-radius: 6px;
        padding: 1rem;
        box-shadow: 0 .5em 1em -.125em rgba(10,10,10,.3),0 0 0 1px rgba(10,10,10,.02);
        -moz-box-shadow: 0 .5em 1em -.125em rgba(10,10,10,.3);
        -webkit-box-shadow: 0 .5em 1em -.125em rgba(10,10,10,.3);
    }

    .has-text-vert-top {
        vertical-align: top;
    }
</style>
